import React from 'react'
import PracticeAreasHeader from './PracticeAreasHeader'
import PMLA from './Images/icons2/pmla.png'
import WhiteCollarCrime from './Images/icons2/white-collar-crime.png'
import FEMA from './Images/icons2/fema.png'
import IncomeTax from './Images/icons2/income-tax.png'
import GST from './Images/icons2/gst.png'
import MA from './Images/icons2/m&a.png'
import InternationalTaxation from './Images/icons2/international-taxation.png'
import ConstitutionalLaws from './Images/icons2/constitutional-law.png'
import CommercialBankingLaws from './Images/icons2/commercial.png'
import PropertyLaws from './Images/icons2/property.png'
import ServiceEmploymentLaws from './Images/icons2/service.png'
import CriminalLaws from './Images/icons2/criminal.png'
import ArbitrationLaws from './Images/icons2/arbitration-law.png'
import RealEstateLaws from './Images/icons2/real-estate.png'
import TaxationLaws from './Images/icons2/taxation.png'
import FinanceInsuranceLaws from './Images/icons2/financial-insurance.png'
import FamilyLaws from './Images/icons2/family.png'
import ConsumerLaws from './Images/icons2/consumer.png'
import CorporateLaws from './Images/icons2/corporate-law.png'

const PracticeAreas = () => {
  return (
    <>
      <PracticeAreasHeader/>
      <div className='container mt-4'>
        <div className='row'>
          <div className='col-12 text-center'>
            <p className='display-6 section-title'>Our Practice Areas</p>
          </div>
        </div>
        <div className='row'>
          <div className='col-md-3 text-center my-2'>
            <div className='arnch-card card shadow'>
              <img className='img-fluid' src={ConstitutionalLaws} alt='Constitutional Laws'/>
              <p>Constitutional Laws</p>
            </div>
          </div>
          <div className='col-md-3 text-center my-2'>
            <div className='arnch-card card shadow'>
              <img className='img-fluid' src={CommercialBankingLaws} alt='CommercialBankingLaws'/>
              <p>Commercial & Banking Laws</p>
            </div>
          </div>
          <div className='col-md-3 text-center my-2'>
            <div className='arnch-card card shadow'>
              <img className='img-fluid' src={PropertyLaws} alt='PropertyLaws'/>
              <p>Property Laws</p>
            </div>
          </div>
          <div className='col-md-3 text-center my-2'>
            <div className='arnch-card card shadow'>
              <img className='img-fluid' src={ServiceEmploymentLaws} alt='ServiceEmploymentLaws'/>
              <p>Service / Employment Laws</p>
            </div>
          </div>
          <div className='col-md-3 text-center my-2'>
            <div className='arnch-card card shadow'>
              <img className='img-fluid' src={CriminalLaws} alt='CriminalLaws'/>
              <p>Criminal Law</p>
            </div>
          </div>
          <div className='col-md-3 text-center my-2'>
            <div className='arnch-card card shadow'>
              <img className='img-fluid' src={ArbitrationLaws} alt='ArbitrationLaws'/>
              <p>Arbitration Law</p>
            </div>
          </div>
          <div className='col-md-3 text-center my-2'>
            <div className='arnch-card card shadow'>
              <img className='img-fluid' src={RealEstateLaws} alt='RealEstateLaws'/>
              <p>Real Estate & Infrastructure Laws</p>
            </div>
          </div>
          <div className='col-md-3 text-center my-2'>
            <div className='arnch-card card shadow'>
              <img className='img-fluid' src={TaxationLaws} alt='TaxationLaws'/>
              <p>Taxation Laws</p>
            </div>
          </div>
          <div className='col-md-3 text-center my-2'>
            <div className='arnch-card card shadow'>
              <img className='img-fluid' src={FinanceInsuranceLaws} alt='FinanceInsuranceLaws'/>
              <p>Finance & Insurance Laws</p>
            </div>
          </div>
          <div className='col-md-3 text-center my-2'>
            <div className='arnch-card card shadow'>
              <img className='img-fluid' src={FamilyLaws} alt='FamilyLaws'/>
              <p>Family Law</p>
            </div>
          </div>
          <div className='col-md-3 text-center my-2'>
            <div className='arnch-card card shadow'>
              <img className='img-fluid' src={ConsumerLaws} alt='ConsumerLaws'/>
              <p>Consumer Law</p>
            </div>
          </div>
          <div className='col-md-3 text-center my-2'>
            <div className='arnch-card card shadow'>
              <img className='img-fluid' src={CorporateLaws} alt='CorporateLaws'/>
              <p>Corporate Law</p>
            </div>
          </div>
          <div className='col-md-3 text-center my-2'>
            <div className='arnch-card card shadow'>
              <img className='img-fluid' src={PMLA} alt='PMLA'/>
              <p>PMLA</p>
            </div>
          </div>
          <div className='col-md-3 text-center my-2'>
            <div className='arnch-card card shadow'>
              <img className='img-fluid' src={WhiteCollarCrime} alt='WhiteCollarCrime'/>
              <p>White Collar Crime</p>
            </div>
          </div>
          <div className='col-md-3 text-center my-2'>
            <div className='arnch-card card shadow'>
              <img className='img-fluid' src={FEMA} alt='FEMA'/>
              <p>FEMA</p>
            </div>
          </div>
          <div className='col-md-3 text-center my-2'>
            <div className='arnch-card card shadow'>
              <img className='img-fluid' src={IncomeTax} alt='IncomeTax'/>
              <p>Income Tax</p>
            </div>
          </div>
          <div className='col-md-3 text-center my-2'>
            <div className='arnch-card card shadow'>
              <img className='img-fluid' src={GST} alt='GST'/>
              <p>GST</p>
            </div>
          </div>
          <div className='col-md-3 text-center my-2'>
            <div className='arnch-card card shadow'>
              <img className='img-fluid' src={MA} alt='MA'/>
              <p>M & A</p>
            </div>
          </div>
          <div className='col-md-3 text-center my-2'>
            <div className='arnch-card card shadow'>
              <img className='img-fluid' src={InternationalTaxation} alt='InternationalTaxation'/>
              <p>International Taxation</p>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default PracticeAreas