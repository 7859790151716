import React from 'react'
import ContactUsSlider from './ContactUsSlider'
// import MapPin from './Images/gps.png'

const ContactUs = () => {
  return (
    <>
      <ContactUsSlider/>
      <div className='container-fluid mt-4'>
        <div className='row'>
          <div className='col-12 text-center'>
            <p className='display-6 section-title'>Contact Us</p>
          </div>
          <div className='col-md-6'>
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d219.0469280840243!2d77.23859953535882!3d28.547207658206762!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce232e62083bd%3A0xa465a1552570616b!2sR143%2C%20Block%20R%2C%20Greater%20Kailash%20I%2C%20Greater%20Kailash%2C%20New%20Delhi%2C%20Delhi%20110048!5e0!3m2!1sen!2sin!4v1720029063991!5m2!1sen!2sin" width="600" height="450" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" title='Arnch Map'></iframe>
          </div>
          <div className='col-md-6'>
            <div className='container-fluid'>
              <div className='row'>
                <div className='col-md-12 text-center my-2'>
                  <div className='card shadow'>
                    {/* <img className='img-fluid' src={MapPin} alt='GPS Pin'/> */}
                    <p></p>
                    <p className='h5 section-title'>New Delhi Office</p>
                    <p>R-143 (Basement), Greater Kailash, Part-1, New Delhi 110048</p>
                  </div>
                </div>
                <div className='col-md-12 text-center my-2'>
                  <div className='card shadow'>
                    {/* <img className='img-fluid' src={MapPin} alt='GPS Pin'/> */}
                    <p></p>
                    <p className='h5 section-title'>Gurugram Office</p>
                    <p>301-B, Flatted Factory Complex, Hartron Technology, Plot No. 25A, Electronic City, Sector-18, Gururgram, Haryana 122015</p>
                  </div>
                </div>
                <div className='col-md-12 text-center my-2'>
                  <div className='card shadow'>
                    {/* <img className='img-fluid' src={MapPin} alt='GPS Pin'/> */}
                    <p></p>
                    <p className='h5 section-title'>Email Address</p>
                    <p>
                      <a href='mailto:info@arnchlegal.in'>info@arnchlegal.in</a>
                    </p>
                  </div>
                </div>
                <div className='col-md-12 text-center my-2'>
                  <div className='card shadow'>
                    {/* <img className='img-fluid' src={MapPin} alt='GPS Pin'/> */}
                    <p></p>
                    <p className='h5 section-title'>Contact Number</p>
                    <p>
                      <a href='tel:01135702230'>011 35702230</a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ContactUs