import React from 'react'
import BDMHeader from './Images/bdm-march-2024-title.png'
import { MDBCarousel, MDBCarouselItem } from 'mdb-react-ui-kit';
import BDM1 from './Images/events/bharat-drone-manthan-march-2024/BDM-1.jpg'
import BDM2 from './Images/events/bharat-drone-manthan-march-2024/BDM-2.jpg'
import BDM3 from './Images/events/bharat-drone-manthan-march-2024/BDM-3.jpg'
import BDM4 from './Images/events/bharat-drone-manthan-march-2024/BDM-4.jpg'
import BDM5 from './Images/events/bharat-drone-manthan-march-2024/BDM-5.jpg'

const BharatDroneManthanMarch2024 = () => {
  return (
    <>
        <MDBCarousel>
          <MDBCarouselItem itemId={1}>
          <img src={BDMHeader} className='d-block w-100' alt='...' />
          </MDBCarouselItem>
        </MDBCarousel>
        <div className='container-fluid mt-4'>
            <div className='row'>
                <div className='col-md-4 text-center my-2'>
                    <img className='img-fluid' src={BDM1} alt='BDM-1'/>
                </div>
                <div className='col-md-4 text-center my-2'>
                    <img className='img-fluid' src={BDM2} alt='BDM-2'/>
                </div>
                <div className='col-md-4 text-center my-2'>
                    <img className='img-fluid' src={BDM3} alt='BDM-3'/>
                </div>
                <div className='col-md-4 text-center my-2'>
                    <img className='img-fluid' src={BDM4} alt='BDM-4'/>
                </div>
                <div className='col-md-4 text-center my-2'>
                    <img className='img-fluid' src={BDM5} alt='BDM-5'/>
                </div>
            </div>
        </div>
    </>
  )
}

export default BharatDroneManthanMarch2024