import React from 'react'
import ServicesSlider from './Images/service-header.jpg'
import { MDBCarousel, MDBCarouselItem } from 'mdb-react-ui-kit';

const OurServicesSlider = () => {
  return (
    <>
        <MDBCarousel>
          <MDBCarouselItem itemId={1}>
          <img src={ServicesSlider} className='d-block w-100' alt='...' />
          </MDBCarouselItem>
        </MDBCarousel>
    </>
  )
}

export default OurServicesSlider