import React from 'react'
import ArnchHomeHeader from './Images/Arnch-Home-Banner.png'
import { MDBCarousel, MDBCarouselItem } from 'mdb-react-ui-kit';

const HomeSlider = () => {
  return (
    <>
      <MDBCarousel>
        <MDBCarouselItem itemId={1}>
          <img src={ArnchHomeHeader} className='d-block w-100' alt='...' />
        </MDBCarouselItem>
        {/* <MDBCarouselItem itemId={2}>
          <img src='https://mdbootstrap.com/img/new/slides/042.jpg' className='d-block w-100' alt='...' />
        </MDBCarouselItem>
        <MDBCarouselItem itemId={3}>
          <img src='https://mdbootstrap.com/img/new/slides/043.jpg' className='d-block w-100' alt='...' />
        </MDBCarouselItem> */}
      </MDBCarousel>
    </>
  )
}

export default HomeSlider