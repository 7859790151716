import React from 'react'
import Slider from "react-slick";
// import CarouselSlider from "react-carousel-slider";
// import './clientslider.scss'
import PMLA from './Images/icons/pmla.png'
import WhiteCollarCrime from './Images/icons/white-collar-crime.png'
import FEMA from './Images/icons/fema.png'
import IncomeTax from './Images/icons/income-tax.png'
import GST from './Images/icons/gst.png'
import MA from './Images/icons/m&a.png'
import InternationalTaxation from './Images/icons/international-taxation.png'
import ConstitutionalLaws from './Images/icons/constitutional-law.png'
import CommercialBankingLaws from './Images/icons/commercial.png'
import PropertyLaws from './Images/icons/property.png'
import ServiceEmploymentLaws from './Images/icons/service.png'
import CriminalLaws from './Images/icons/criminal.png'
import ArbitrationLaws from './Images/icons/arbitration-law.png'
import RealEstateLaws from './Images/icons/real-estate.png'
import TaxationLaws from './Images/icons/taxation.png'
import FinanceInsuranceLaws from './Images/icons/financial-insurance.png'
import FamilyLaws from './Images/icons/family.png'
import ConsumerLaws from './Images/icons/consumer.png'
import CorporateLaws from './Images/icons/corporate-law.png'

const PracticeAreasSlider = () => {
    var settings = {
    dots: false,
    infinite: true,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    pauseOnHover: true
      };
  return (
    <>
        <div className="slider-container">
            <Slider {...settings}>
                <div className='text-center'>
                    <img className='img-fluid' src={PMLA} alt='PMLA'/><br/>
                    <h5>PMLA</h5>
                </div>
                <div className='text-center'>
                    <img className='img-fluid' src={WhiteCollarCrime} alt='WhiteCollarCrime'/><br/>
                    <h5>White Collar Crime</h5>
                </div>
                <div className='text-center'>
                    <img className='img-fluid' src={FEMA} alt='FEMA'/><br/>
                    <h5>FEMA</h5>
                </div>
                <div className='text-center'>
                    <img className='img-fluid' src={IncomeTax} alt='IncomeTax'/><br/>
                    <h5>Income Tax</h5>
                </div>
                <div className='text-center'>
                    <img className='img-fluid' src={GST} alt='GST'/><br/>
                    <h5>GST</h5>
                </div>
                <div className='text-center'>
                    <img className='img-fluid' src={MA} alt='MA'/><br/>
                    <h5>M & A</h5>
                </div>
                <div className='text-center'>
                    <img className='img-fluid' src={InternationalTaxation} alt='InternationalTaxation'/><br/>
                    <h5>International Taxation</h5>
                </div>
                <div className='text-center'>
                    <img className='img-fluid' src={ConstitutionalLaws} alt='ConstitutionalLaws'/><br/>
                    <h5>Constitutional Laws</h5>
                </div>
                <div className='text-center'>
                    <img className='img-fluid' src={CommercialBankingLaws} alt='CommercialBankingLaws'/><br/>
                    <h5>Commercial & Banking Laws</h5>
                </div>
                <div className='text-center'>
                    <img className='img-fluid' src={PropertyLaws} alt='PropertyLaws'/><br/>
                    <h5>Property Laws</h5>
                </div>
                <div className='text-center'>
                    <img className='img-fluid' src={ServiceEmploymentLaws} alt='ServiceEmploymentLaws'/><br/>
                    <h5>Service & Employment Laws</h5>
                </div>
                <div className='text-center'>
                    <img className='img-fluid' src={CriminalLaws} alt='CriminalLaws'/><br/>
                    <h5>Criminal Laws</h5>
                </div>
                <div className='text-center'>
                    <img className='img-fluid' src={ArbitrationLaws} alt='ArbitrationLaws'/><br/>
                    <h5>Arbitration Laws</h5>
                </div>
                <div className='text-center'>
                    <img className='img-fluid' src={RealEstateLaws} alt='RealEstateLaws'/><br/>
                    <h5>Real Estate Laws</h5>
                </div>
                <div className='text-center'>
                    <img className='img-fluid' src={TaxationLaws} alt='TaxationLaws'/><br/>
                    <h5>Taxation Laws</h5>
                </div>
                <div className='text-center'>
                    <img className='img-fluid' src={FinanceInsuranceLaws} alt='FinanceInsuranceLaws'/><br/>
                    <h5>Finance & Insurance Laws</h5>
                </div>
                <div className='text-center'>
                    <img className='img-fluid' src={FamilyLaws} alt='FamilyLaws'/><br/>
                    <h5>Family Laws</h5>
                </div>
                <div className='text-center'>
                    <img className='img-fluid' src={ConsumerLaws} alt='ConsumerLaws'/><br/>
                    <h5>Consumer Laws</h5>
                </div>
                <div className='text-center'>
                    <img className='img-fluid' src={CorporateLaws} alt='CorporateLaws'/><br/>
                    <h5>Corporate Laws</h5>
                </div>
            </Slider>
        </div>
    </>
  )
}

export default PracticeAreasSlider