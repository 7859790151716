import React from 'react'
import Govinda from './Images/govinda-ramanan.jpg'
import FounderSlider from './FounderSlider'

const GovindaRamanan = () => {
  return (
    <>
        <FounderSlider/>
        <div className='container mt-4'>
            <div className='row'>
                <div className='col-md-4 text-center'>
                    <img className='img-fluid' src={Govinda} alt='Govinda Ramanan'/>
                </div>
                <div className='col-md-8'>
                    <p align='justify'>
                        As the founder and Managing Partner of the firm, he has an experience of advisory and litigation in both criminal and fiscal laws. He has been appearing regularly before the constitutional courts, criminal courts, authorities and tribunals in India especially in view of the provisions of PBPT, PMLA, Companies Act, 2013 (Serious Fraud Investigation Office), Directorate of Enforcement (ED), SARFAESI, Consumer Protection Act, Real Estate (Regulation and development) Act ‘RERA’, POCSO, among others.
                    </p>
                    <p align='justify'>
                        He also has a versatile experience in business litigation, including the White-Collar Crimes such as EOW Cases, Serious Fraud Investigation office (SFIO) cases, cases concerning the Directorate of Enforcement, Civil laws and Corporate Advisory. He is a passionate litigator and a dynamic leader, who leads by example in courtrooms and board rooms alike. He tempers such ferocity with true empathy towards his clientele. Most clients who interact with him, look up to him as a trusted friend rather than a mere legal representative.
                    </p>
                    <p align='justify'>
                        He is a dynamic partner of the firm and as such he has special skills in drafting of the pleadings including SLP(s), Writ Petitions, Civil Appeal before the Hon’ble Supreme Court, Hon’ble High Courts and various other Tribunals and authorities situated in the country. He has been advising in complex multi-jurisdictional prosecutions being carried out by multiple investigative agencies and as such he continuously traverses between prominent Indian and International companies, high net-worth individuals, and elite families. He has led critical mandates involving anti-money laundering laws, Serious Fraud Investigations, investigations by the Directorate of Enforcement including the preventive compliances.
                    </p>
                </div>
            </div>
        </div>
    </>
  )
}

export default GovindaRamanan