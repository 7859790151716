import React from 'react'
import DisputePage from './Images/dispute-page.jpg'
import OurServicesSlider from './OurServicesSlider'

const Dispute = () => {
  return (
    <>
        <OurServicesSlider/>
        <div className='container-fluid mt-4'>
            <div className='row'>
                <div className='col-md-4 text-center'>
                    <img className='img-fluid' src={DisputePage} alt='Dispute'/>
                </div>
                <div className='col-md-8'>
                    <p align='justify'>
                        The firm was set up with the aim to be a ‘One stop-house’, providing every legal service under an affordable roof. Our firm does not seek merely to be a liaison office between clients and senior/arguing Counsels. Our Counsels with more than two decades of experience, mostly represent and argue their own cases, in their attempt to provide quality and affordable professional services that match the highest standards of advocacy and advisory services, without any prejudice or compromise. The plethora of cases presented and argued by them over the last two decades provides adequate and ample testimony of their credentials.
                    </p>
                    <p>In the field of disputes we have:</p>
                    <p align='justify'>
                        <ul>
                            <li>Successfully represented and litigated in almost every branch/segment of litigation practice whether civil, criminal, commercial, constitutional, contractual, service, banking, environmental, public interest etc. Our experience spreads through various forums including Tribunals, Commissions, High Courts and the Supreme Court of India.</li>
                            <li>Succeeded in getting the service rules framed by the Union Public Service Commission for selection of Indian Administrative Services (IAS) and other allied officers declared illegal and discriminatory. The service rules framed by the Government of India were struck down being both arbitrary and unconstitutional that sought to deny an individual the right to equality.</li>
                            <li>Contributed to the development and evolution of law of recovery of debt under the Securitization And Reconstruction Of Financial Assets And Enforcement Of Security Interest Act, 2002 in lying the law that:
                                <ul>
                                    <li>The right to travel is a Fundamental right which no banking tribunal can deny in exercise of power.</li>
                                    <li>Authentic and lawful classification of an account as non-performing asset is a sin quo non for any proceeding to commence under section 13 of the Act.</li>
                                </ul>
                            </li>
                            <li>Represented Director/Companies from protecting them against the abuse under criminal proceedings by invoking quashing powers.</li>
                            <li>Challenged and secured the quashing of State government notification seeking to divide communities/tribes being violative of Article 14.</li>
                            <li>Contributed to the development of law regarding the refund of pre-deposits in recovery cases.</li>
                            <li>Contributed and pioneered the research and development of law in many vital areas, such as constitutional, banking, recovery, financial, criminal, service, environmental law etc.</li>
                            <li>Contributed to the service jurisprudence and the rights of a borrower/debtor in any banking transaction.</li>
                            <li>Associated with the marginalized and the impoverished, in the field of pro bono litigation, taking their grievances right to the doors of the Supreme Court.</li>
                            <li>Successfully secured financial benefits to a leprosy victim abandoned by its employer/ government of India.</li>
                            <li>In the sphere of arbitration, the firm enjoys the distinction of having secured award against a multinational giant involving millions as damages, within a limited window of a mere 12-14 months.</li>
                            <li>On the other hand, we have also challenged and succeeded in getting set aside Arbitral awards.</li>
                            <li>The firm through its numerous articles has highlighted the various laws that require intervention or changes.</li>
                            <li>We have often argued that Section 37 of the Arbitration and Conciliation Act is retrograde and contradictory to the very notion of a progressive or robust arbitrational law. Though civil courts only provide a two-tier appellate system our law makers framed an alternate dispute mechanism with a three-tier system.</li>
                            <li>Like minded concerns are continually voiced by the firm in what it considers to be unjust and unfair.</li>
                        </ul>
                    </p>
                </div>
            </div>
        </div>
    </>
  )
}

export default Dispute