import React from 'react'
import EventsHeaderImage from './Images/events-header.jpg'
import { MDBCarousel, MDBCarouselItem } from 'mdb-react-ui-kit';

const EventsHeader = () => {
  return (
    <>
        <MDBCarousel>
          <MDBCarouselItem itemId={1}>
          <img src={EventsHeaderImage} className='d-block w-100' alt='...' />
          </MDBCarouselItem>
      </MDBCarousel>
    </>
  )
}

export default EventsHeader