import React from 'react'
import {
    MDBFooter,
    MDBContainer,
    // MDBIcon,
    // MDBInput,
    MDBCol,
    MDBRow,
    // MDBBtn
  } from 'mdb-react-ui-kit';

const Footer = () => {
  return (
    <>
        <MDBFooter className='text-center footer-text' color='white' bgColor='dark'>
            <MDBContainer className='p-4'>
                {/* <section className='mb-4'>
                <MDBBtn outline color="light" floating className='m-1' href='#!' role='button'>
                    <MDBIcon fab icon='facebook-f' />
                </MDBBtn>

                <MDBBtn outline color="light" floating className='m-1' href='#!' role='button'>
                    <MDBIcon fab icon='twitter' />
                </MDBBtn>

                <MDBBtn outline color="light" floating className='m-1' href='#!' role='button'>
                    <MDBIcon fab icon='google' />
                </MDBBtn>

                <MDBBtn outline color="light" floating className='m-1' href='#!' role='button'>
                    <MDBIcon fab icon='instagram' />
                </MDBBtn>

                <MDBBtn outline color="light" floating className='m-1' href='#!' role='button'>
                    <MDBIcon fab icon='linkedin-in' />
                </MDBBtn>

                <MDBBtn outline color="light" floating className='m-1' href='#!' role='button'>
                    <MDBIcon fab icon='github' />
                </MDBBtn>
                </section> */}

                {/* <section className=''>
                <form action=''>
                    <MDBRow className='d-flex justify-content-center'>
                    <MDBCol size="auto">
                        <p className='pt-2'>
                        <strong>Sign up for our newsletter</strong>
                        </p>
                    </MDBCol>

                    <MDBCol md='5' start>
                        <MDBInput contrast type='email' label='Email address' className='mb-4' />
                    </MDBCol>

                    <MDBCol size="auto">
                        <MDBBtn outline color='light' type='submit' className='mb-4'>
                        Subscribe
                        </MDBBtn>
                    </MDBCol>
                    </MDBRow>
                </form>
                </section> */}

                <section className='mb-4'>
                <p className='footer-text'>
                Arnch is a distinctive firm as it provides both Advisory and Litigation services and provides expert assistance to clients, which includes High Networth Individuals (HNI), companies and corporate houses. The concentrated team at Arnch Legal has litigation expertise before all criminal courts and tribunals in India. 
                </p>
                </section>

                <section className=''>
                <MDBRow>
                    <MDBCol lg='3' md='6' className='mb-4 mb-md-0'>
                    <h5 className='text-uppercase footer-text'>New Delhi Office</h5>

                    <ul className='list-unstyled mb-0'>
                        <li>
                        <a href='#!' className='footer-text'>
                            R-143 (Basement), Greater Kailash, Part-1, New Delhi 110048
                        </a>
                        </li>
                        {/* <li>
                        <a href='#!' className='footer-text'>
                            Link 2
                        </a>
                        </li>
                        <li>
                        <a href='#!' className='text-white'>
                            Link 3
                        </a>
                        </li>
                        <li>
                        <a href='#!' className='text-white'>
                            Link 4
                        </a>
                        </li> */}
                    </ul>
                    </MDBCol>

                    <MDBCol lg='3' md='6' className='mb-4 mb-md-0'>
                    <h5 className='text-uppercase footer-text'>Gurugram Office</h5>

                    <ul className='list-unstyled mb-0'>
                        <li>
                        <a href='#!' className='text-white'>
                        301-B, Flatted Factory Complex, Hartron Technology, Plot No. 25A, Electronic City, Sector-18, Gururgram, Haryana 122015
                        </a>
                        </li>
                        {/* <li>
                        <a href='#!' className='text-white'>
                            Link 2
                        </a>
                        </li>
                        <li>
                        <a href='#!' className='text-white'>
                            Link 3
                        </a>
                        </li>
                        <li>
                        <a href='#!' className='text-white'>
                            Link 4
                        </a>
                        </li> */}
                    </ul>
                    </MDBCol>

                    <MDBCol lg='3' md='6' className='mb-4 mb-md-0'>
                    <h5 className='text-uppercase footer-text'>Email Address</h5>

                    <ul className='list-unstyled mb-0'>
                        <li>
                        <a href='mailto:info@arnchlegal.in' className='text-white'>
                        info@arnchlegal.in
                        </a>
                        </li>
                        {/* <li>
                        <a href='#!' className='text-white'>
                            Link 2
                        </a>
                        </li>
                        <li>
                        <a href='#!' className='text-white'>
                            Link 3
                        </a>
                        </li>
                        <li>
                        <a href='#!' className='text-white'>
                            Link 4
                        </a>
                        </li> */}
                    </ul>
                    </MDBCol>

                    <MDBCol lg='3' md='6' className='mb-4 mb-md-0'>
                    <h5 className='text-uppercase footer-text'>Contact Number</h5>

                    <ul className='list-unstyled mb-0'>
                        <li>
                        <a href='tel:01135702230' className='text-white'>
                        011 35702230
                        </a>
                        </li>
                        {/* <li>
                        <a href='#!' className='text-white'>
                            Link 2
                        </a>
                        </li>
                        <li>
                        <a href='#!' className='text-white'>
                            Link 3
                        </a>
                        </li>
                        <li>
                        <a href='#!' className='text-white'>
                            Link 4
                        </a>
                        </li> */}
                    </ul>
                    </MDBCol>
                </MDBRow>
                </section>
            </MDBContainer>

            <div className='text-center p-3 footer-text' style={{ backgroundColor: 'rgba(0, 0, 0, 0.2)' }}>
                © 2024 Copyright 
                <a className='text-white' href='https://arnchlegal.in/'> Arnch Legal LLP</a>
            </div>
        </MDBFooter>
    </>
  )
}

export default Footer