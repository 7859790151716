import React from 'react'
import FounderHeader from './Images/founder-header.jpg'
import { MDBCarousel, MDBCarouselItem } from 'mdb-react-ui-kit';

const FounderSlider = () => {
  return (
    <>
      <MDBCarousel>
          <MDBCarouselItem itemId={1}>
          <img src={FounderHeader} className='d-block w-100' alt='...' />
          </MDBCarouselItem>
      </MDBCarousel>
    </>
  )
}

export default FounderSlider