import React from 'react'
import FounderSlider from './FounderSlider'
import Ashish from './Images/ashish-kumar.jpg'

const AshishKumar = () => {
  return (
    <>
        <FounderSlider/>
        <div className='container mt-4'>
            <div className='row mb-2'>
                <div className='col-md-4 text-center'>
                    <img className='img-fluid' src={Ashish} alt='Ashish Kumar'/>
                </div>
                <div className='col-md-8'>
                    <p align='justify'>
                        Being one of the founding Partner of the firm, Mr. Ashish Kumar is also one of the prominent and leading technocrat of the country who is providing technological solutions/advisory for smart cities, smart manufacturing, smart villages to the Govt.
                    </p>
                    <p align='justify'>
                        Mr. Kumar is also the member of CII (Confederation of Indian Industry), thereby, playing an important role in the committee on Artificial Intelligence (AI), 5G implementation and policy making for industry.
                    </p>
                    <p align='justify'>
                        He is also the founder of “Roar up Bharat”, which is special think tank organisation, dedicated to the holistic development of the Indian Industry as a whole and as such he is also the go to man, when it comes to corporate advisory including Corporate Mergers and Acquisitions [M&A], Financial Services, Dispute resolution among corporate houses.
                    </p>
                </div>
            </div>
        </div>
    </>
  )
}

export default AshishKumar