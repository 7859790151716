import React from 'react'
import HomeSlider from './HomeSlider'
// import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
// import CardActions from '@mui/material/CardActions';
// import CardContent from '@mui/material/CardContent';
// import Button from '@mui/material/Button';
// import Typography from '@mui/material/Typography';
import LawBalance from './Images/law-balance.png'
import Corporate from './Images/corporate-image.png'
import Dispute from './Images/dispute-image.png'
import Finance from './Images/fiinance-image.png'
import PracticeAreasSlider from './PracticeAreasSlider';

const Home = () => {
  return (
    <>
      <HomeSlider/>
      <div className='container-fluid'>
        <div className='row mt-4'>
          <div className='col-12 text-center'>
            <p className='h2 quotes'>
              "Injustice anywhere is a threat to justice everywhere." - Martin Luther King
            </p>
          </div>
        </div>
      </div>
      <div className='container-fluid mt-4'>
        <Card sx={{minWidth: '100%'}}>
          <div className='row mt-4 mb-2'> 
            <div className='col-12'>
              <p className='display-6 section-title text-center'>Who We Are?</p>
            </div>
            <div className='col-md-6'>
              <p align='justify'>
                ARNCH LEGAL is a specialist law firm having its presence throughout the country with its base in New Delhi. Arnch Legal is providing a full spectrum of solutions in laws dealing with economic offences. The Firm has a unique combination of Advocates and other professionals. Arnch has carved out an expertise, among others, in the new Benami Law (PBPT Act, 1988), Money Laundering Law (PMLA, 2002) including the entire gamut of Civil & Criminal Litigation.
              </p>
              <p align='justify'>
                Arnch is a distinctive firm as it provides both Advisory and Litigation services and provides expert assistance to clients, which includes High Networth Individuals (HNI), companies and corporate houses. The concentrated team at Arnch Legal has litigation expertise before all criminal courts and tribunals in India.
              </p>
              <p align='justify'>
                Further keeping in mind the multifaceted nature of transactions, our entire team has proficiently applied their knowledge across legal transactions across diverse practice areas, industries and jurisdictions. We work with companies, financial institutions and governments to execute the most significant deals and to resolve disputes.
              </p>
              <p align='justify'>
                We offer a full range legal services across a broad spectrum of practice areas that includes Corporate Advisory, Dispute Resolution and Tax advisory and Litigation.
              </p>
            </div>
            <div className='col-md-6 text-center'>
              <img className='img-fluid' src={LawBalance} alt='Law Balance'/>
            </div>
          </div>
        </Card>
      </div>
      <div className='container-fluid mt-4'>
        <div className='row'>
          <div className='col-12 text-center'>
            <p className='display-6 section-title text-center'>Services We Offer</p>
          </div>
        </div>
      </div>
      <div className='container mt-4'>
        <div className='row'>
          <div className='col-md-4'>
            <div id='cards_landscape_wrap-2'>
              <div className='card-flyer'>
                <img className='img-fluid' src={Corporate} alt='Corporate Header'/>
                <p className='card-text display-6 section-title mb-2 card-text'>CORPORATE</p>
              </div>
            </div>
          </div>
          <div className='col-md-4'>
            <div id='cards_landscape_wrap-2'>
              <div className='card-flyer'>
                <img className='img-fluid' src={Dispute} alt='Dispute Header'/>
                <p className='card-text display-6 section-title mb-2 card-text'>DISPUTE</p>
              </div>
            </div>
          </div>
          <div className='col-md-4'>
            <div id='cards_landscape_wrap-2'>
              <div className='card-flyer'>
                <img className='img-fluid' src={Finance} alt='Finance Header'/>
                <p className='card-text display-6 section-title mb-2 card-text'>FINANCE</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='container-fluid mt-4'>
        <div className='row'>
          <div className='col-12 text-center'>
            <p className='display-6 section-title text-center'>Our Practice Areas</p>
          </div>
          <div className='col-12 text-center'>
            <PracticeAreasSlider/>
          </div>
        </div>
      </div>
      <div className='container-fluid mt-4'>
        <div className='row'>
          <div className='col-12 text-center'>
            <p className='display-6 section-title text-center'>Your Connection to Your Case</p>
            <p align='justify'>At <strong><span className='arnch-primary'>ARNCH LEGAL LLP</span></strong> we’re committed to providing you not only with quality legal representation, but also with excellent, individualized service. You don’t have to navigate the complicated — and sometimes mystifying — legal process alone. We’re here to be your connection to the legal system and to advocate for you from the moment you call us until your case is resolved.</p>
            <p className='display-6'>
              <span className='arnch-primary'>THREE THINGS YOU’LL GET WHEN YOU HIRE ARNCH LEGAL LLP FOR YOUR CASE:</span>
            </p>
          </div>
          <div className='col-md-4'>
            <Card>
              <p className='text-center'>A lawyer will answer all your calls and emails</p>
            </Card>
          </div>
          <div className='col-md-4'>
            <Card>
              <p className='text-center'>You'll be informed about your case every step of the way</p>
            </Card>
          </div>
          <div className='col-md-4'>
            <Card>
              <p className='text-center'>You'll be treated with compassion & respect</p>
            </Card>
          </div>
        </div>
      </div>
    </>
  )
}

export default Home