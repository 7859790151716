import React from 'react'
import NGICHeader from './Images/ngic-march-2024-header.png'
import { MDBCarousel, MDBCarouselItem } from 'mdb-react-ui-kit';
import NGIC1 from './Images/events/national-green-infrastructure-conclave-march-2024/NGIC-1.jpg'
import NGIC2 from './Images/events/national-green-infrastructure-conclave-march-2024/NGIC-2.jpg'
import NGIC3 from './Images/events/national-green-infrastructure-conclave-march-2024/NGIC-3.jpg'
import NGIC4 from './Images/events/national-green-infrastructure-conclave-march-2024/NGIC-4.jpg'

const NationalGreenInfrastructureConclaveMarch2024 = () => {
  return (
    <>
        <MDBCarousel>
          <MDBCarouselItem itemId={1}>
          <img src={NGICHeader} className='d-block w-100' alt='...' />
          </MDBCarouselItem>
        </MDBCarousel>
        <div className='container-fluid mt-4'>
            <div className='row'>
                <div className='col-md-4 text-center my-2'>
                    <img className='img-fluid' src={NGIC1} alt='NGIC1'/>
                </div>
                <div className='col-md-4 text-center my-2'>
                    <img className='img-fluid' src={NGIC2} alt='NGIC2'/>
                </div>
                <div className='col-md-4 text-center my-2'>
                    <img className='img-fluid' src={NGIC3} alt='NGIC3'/>
                </div>
                <div className='col-md-4 text-center my-2'>
                    <img className='img-fluid' src={NGIC4} alt='NGIC4'/>
                </div>
            </div>
        </div>
    </>
  )
}

export default NationalGreenInfrastructureConclaveMarch2024