import React from 'react'
import FounderSlider from './FounderSlider'
import AshishKumar from './Images/ashish-kumar.jpg'
import GovindaRamanan from './Images/govinda-ramanan.jpg'

const Founder = () => {
  return (
    <>
      <FounderSlider/>
      <div className='container-fluid mt-4'>
        <div className='row'>
          <div className='col-12 text-center'>
            <p className='display-6 section-title'>
              Meet Our Founders
            </p>
          </div>
        </div>
      </div>
      <div className='container mt-4'>
        <div className='row mb-2'>
          <div className='col-md-3'></div>
          <div className='col-md-3 text-center'>
            <a href='ashishkumar'>
              <div className='card shadow'>
                <img className='img-fluid' src={AshishKumar} alt='Ashish Kumar'/>
                <br/><p className='h5'>Ashish Kumar</p>
              </div>
            </a>
          </div>
          <div className='col-md-3 text-center'>
            <a href='govindaramanan'>
              <div className='card shadow'>
                <img className='img-fluid' src={GovindaRamanan} alt='Govinda Ramanan'/>
                <br/><p className='h5'>Govinda Ramanan</p>
              </div>
            </a>
          </div>
          <div className='col-md-3'></div>
        </div>
      </div>
    </>
  )
}

export default Founder