import React, { useState } from 'react'
import {
    MDBContainer,
    MDBNavbar,
    MDBNavbarBrand,
    MDBNavbarToggler,
    MDBNavbarNav,
    MDBNavbarItem,
    MDBNavbarLink,
    MDBIcon,
    MDBCollapse
  } from 'mdb-react-ui-kit';
  import ArnchWebLogo from './Images/arnch-legal-logo.png'

const Navbar = () => {
    const [openNavRight, setOpenNavRight] = useState(false);
  return (
    <>
        <MDBNavbar expand='lg' light bgColor='light' sticky='top'>
            <MDBContainer fluid>
                <MDBNavbarBrand href='/'>
                    <img className='img-fluid' src={ArnchWebLogo} alt='Arnch Legal Logo'/>
                </MDBNavbarBrand>
                <MDBNavbarToggler
                type='button'
                data-target='#navbarRightAlignExample'
                aria-controls='navbarRightAlignExample'
                aria-expanded='false'
                aria-label='Toggle navigation'
                onClick={() => setOpenNavRight(!openNavRight)}
                >
                <MDBIcon icon='bars' fas />
                </MDBNavbarToggler>
                <MDBCollapse navbar open={openNavRight}>
                    <MDBNavbarNav right fullWidth={false} className='mb-2 mb-lg-0'>
                        <MDBNavbarItem><MDBNavbarLink active aria-current='page' href='/' className='main-menu'>Home</MDBNavbarLink></MDBNavbarItem>
                        <MDBNavbarItem><MDBNavbarLink href='aboutus' className='main-menu'>About Us</MDBNavbarLink></MDBNavbarItem>
                        <MDBNavbarItem><MDBNavbarLink href='founder' className='main-menu'>Founder</MDBNavbarLink></MDBNavbarItem>
                        <MDBNavbarItem><MDBNavbarLink href='ourservices' className='main-menu'>Our Services</MDBNavbarLink></MDBNavbarItem>
                        <MDBNavbarItem><MDBNavbarLink href='practiceareas' className='main-menu'>Practice Areas</MDBNavbarLink></MDBNavbarItem>
                        <MDBNavbarItem><MDBNavbarLink href='events' className='main-menu'>Events</MDBNavbarLink></MDBNavbarItem>
                        <MDBNavbarItem><MDBNavbarLink href='contactus' className='main-menu'>Contact Us</MDBNavbarLink></MDBNavbarItem>
                    </MDBNavbarNav>
                </MDBCollapse>
            </MDBContainer>
        </MDBNavbar>
    </>
  )
}

export default Navbar