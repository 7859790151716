import React from 'react'
import FinanceHeader from './Images/finance-page.jpg'
import OurServicesSlider from './OurServicesSlider'

const Finance = () => {
  return (
    <>
        <OurServicesSlider/>
        <div className='container-fluid mt-4'>
            <div className='row'>
                <div className='col-md-4 text-center'>
                    <img className='img-fluid' src={FinanceHeader} alt='Finance'/>
                </div>
                <div className='col-md-8'>
                    <p align='justify'>
                        The world of banking and finance law is fast-paced and intense. Keeping abreast with the ever evolving legal and regulatory regime the firm has represented and garnered extensive experience & specialization in Banking, Insurance and Finance sectors. The firm appears regularly before National Company Law Tribunal, National Company Law Appellate Tribunal, both at Delhi and other states. Our team members serve on the panel of various insurance companies and are also on the advisory board of many companies.
                    </p>
                    <p align='justify'>
                        In the field of Finance, we:
                    </p>
                    <p align='justify'>
                        <ul>
                            <li>Negotiate and document the contractual relationship between lenders and borrowers, and to ensure that their clients' best legal and commercial interests are reflected in the terms of loan agreements.</li>
                            <li>Provide advisory services to domestic and international clients on the foreign exchange regulations pertaining to investments/transactions in the banking sector.</li>
                            <li>Assist clients in availing External Commercial Borrowings by drafting and reviewing the documents and procuring approvals from the RBI and banks in making representations before the Board for Industrial & Financial Reconstruction and appellate board (BIFR) for recovery of their dues from sick companies.</li>
                            <li>Have appeared and argued in a wide variety of cases, and have equally contributed to the development of legal research and law, in the Banking, Bankruptcy, insolvency and other range of commercial laws.</li>
                            <li>Have formidable expertise in the Securitization and Debt Recovery Laws.</li>
                            <li>Have extensive expertise in the areas of seed/venture capital financing, technology transactions, mergers & acquisitions, joint ventures, exchange control laws, venture capital, private equity and general corporate and commercial transactions.</li>
                            <li>We help Start-Up’s</li>
                            <li>Identify sources of financing.</li>
                            <li>Conduct in depth analysis of how to raise initial capital to finance the business.</li>
                        </ul>
                    </p>
                </div>
            </div>
        </div>
    </>
  )
}

export default Finance