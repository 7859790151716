import React from 'react'
import OurServicesSlider from './OurServicesSlider'
import Corporate from './Images/corporate-image.png'
import Dispute from './Images/dispute-image.png'
import Finance from './Images/fiinance-image.png'

const OurServices = () => {
  return (
    <>
      <OurServicesSlider/>
      <div className='container-fluid mt-4'>
        <div className='row'>
          <div className='col-12 text-center'>
            <p className='display-6 section-title text-center'>Services We Offer</p>
          </div>
        </div>
      </div>
      <div className='container mt-4'>
        <div className='row'>
            <div className='col-md-4'>
              <a href='corporate'>
                <div id='cards_landscape_wrap-2'>
                  <div className='card-flyer'>
                    <img className='img-fluid' src={Corporate} alt='Corporate Header'/>
                    <p className='card-text display-6 section-title mb-2 card-text'>CORPORATE</p>
                  </div>
                </div>
              </a>
            </div>
          <div className='col-md-4'>
            <a href='dispute'>
              <div id='cards_landscape_wrap-2'>
                <div className='card-flyer'>
                  <img className='img-fluid' src={Dispute} alt='Dispute Header'/>
                  <p className='card-text display-6 section-title mb-2 card-text'>DISPUTE</p>
                </div>
              </div>
            </a>
          </div>
          <div className='col-md-4'>
            <a href='finance'>
              <div id='cards_landscape_wrap-2'>
                <div className='card-flyer'>
                  <img className='img-fluid' src={Finance} alt='Finance Header'/>
                  <p className='card-text display-6 section-title mb-2 card-text'>FINANCE</p>
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
    </>
  )
}

export default OurServices