import React from 'react'
import OurServicesSlider from './OurServicesSlider'
import CorporateImage from './Images/corporate-page.jpg'

const Corporate = () => {
  return (
    <>
        <OurServicesSlider/>
        <div className='container-fluid mt-4'>
            <div className='row'>
                <div className='col-md-4 text-center'>
                    <img className='img-fluid' src={CorporateImage} alt='Corporate'/>
                </div>
                <div className='col-md-8'>
                    <p align='justify'>
                        We know that no two corporate transactions are the same. To service the diverse needs of various clients we have a team of specialized lawyers, with in depth knowledge and experience in a variety of laws including Corporate, Commercial, Labour, Environment, IPR laws and mastery in the field of dispute avoidance & resolution. Our team provides sound, pragmatic and bespoke advice to help clients strategize and execute their transactions in an impeccable and seamless manner. Keeping the specific needs of every transaction in mind we draft special clauses in contracts and agreements for dispute avoidance and resolution.
                    </p>
                    <p align='justify'>
                        Our corporate practice encompasses:
                    </p>
                    <p align='justify'>
                        <ul>
                            <li>Transactional, legal advisory and compliance services pertaining to day-to-day business, corporate and governance affairs of companies.</li>
                            <li>Providing legal assistance in various matters including, mergers & acquisitions, joint ventures, exchange control laws, liquidation and winding up.</li>
                            <li>Conducting “due diligence” checks.</li>
                            <li>Providing security and credit support review for the lender and the borrower.</li>
                            <li>Anti-Bribery/Anti-Corruption investigations.</li>
                            <li>Advising clients on strategic, regulatory and tax issues at the investor level.</li>
                            <li>Negotiating, drafting, and reviewing wide range of contracts including joint ventures, business transfers, non-compete, leasing, licensing, distribution, employment, franchising and various other essential business contracts.</li>
                            <li>Providing legal assistance in various matters including, mergers & acquisitions, joint ventures, exchange control laws, liquidation and winding up.</li>
                            <li>We help Start-Up’s.</li>
                            <li>Identify how to structure their business as a corporation, a partnership, a limited liability company, a sole proprietorship, or even as a joint venture (a partnership arrangement with an established company).</li>
                            <li>Negotiate, draft and execute sound contracts and agreements in order to avoid disputes and other complications.</li>
                            <li>Develop and protect Intellectual Property.</li>
                            <li>Develop a Privacy policy.</li>
                            <li>Navigate the regulatory environment.</li>
                            <li>Understand the implications of central and state tax requirements.</li>
                            <li>Limit the liability of Director’s, partners and stakeholders.</li>
                        </ul>
                    </p>
                </div>
            </div>
        </div>
    </>
  )
}

export default Corporate