import React from 'react'
import EventsHeader from './EventsHeader'
import BDMTitle from './Images/events/bharat-drone-manthan-march-2024/BDM-4.jpg'
import NGICTitle from './Images/events/national-green-infrastructure-conclave-march-2024/NGIC-4.jpg'

const Events = () => {
  return (
    <>
      <EventsHeader/>
      <div className='container-fluid mt-4'>
        <div className='row'>
          <div className='col-12 text-center'>
            <p className='display-6 section-title'>Recent Events</p>
          </div>
        </div>
      </div>
      <div className='container-fluid mt-4'>
        <div className='row'>
          <div className='col-md-3 text-center mb-3'>
            <a href='bharat-drone-manthan-march-2024'>
              <div className='card shadow'>
                <img className='img-fluid' src={BDMTitle} alt='Bharat Drone Manthan Title'/>
                <p></p><p className='h5 section-title'>Bharat Drone Manthan</p>
                <p>March 2024</p>
              </div>
            </a>
          </div>
          <div className='col-md-3 text-center mb-3'>
            <a href='national-green-infra-march-2024'>
              <div className='card shadow'>
                <img className='img-fluid' src={NGICTitle} alt='National Green Infrastructure Conclave Title'/>
                <p></p><p className='h5 section-title'>National Green Infrastructure Conclave</p>
                <p>March 2024</p>
              </div>
            </a>
          </div>
        </div>
      </div>
    </>
  )
}

export default Events