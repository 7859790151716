// import './App.css';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./Components/Home";
import Navbar from "./Components/Navbar";
import AboutUs from "./Components/AboutUs";
import ContactUs from "./Components/ContactUs";
import Events from "./Components/Events";
import Founder from "./Components/Founder";
import OurServices from "./Components/OurServices";
import PracticeAreas from "./Components/PracticeAreas";
import AshishKumar from "./Components/AshishKumar";
import GovindaRamanan from "./Components/GovindaRamanan";
import Corporate from "./Components/Corporate";
import Dispute from "./Components/Dispute";
import Finance from "./Components/Finance";
import BharatDroneManthanMarch2024 from "./Components/BharatDroneManthanMarch2024";
import NationalGreenInfrastructureConclaveMarch2024 from "./Components/NationalGreenInfrastructureConclaveMarch2024";
import Footer from "./Components/Footer";


function App() {
  return (
    <>
      <Navbar/>
      <BrowserRouter>
        <Routes>
          <Route index element = {<Home/>}></Route>
          <Route path="/" element = {<Home/>}></Route>
          <Route path="aboutus" element = {<AboutUs/>}></Route>
          <Route path="contactus" element = {<ContactUs/>}></Route>
          <Route path="events" element = {<Events/>}></Route>
          <Route path="founder" element = {<Founder/>}></Route>
          <Route path="ourservices" element = {<OurServices/>}></Route>
          <Route path="practiceareas" element = {<PracticeAreas/>}></Route>
          <Route path="ashishkumar" element = {<AshishKumar/>}></Route>
          <Route path="govindaramanan" element = {<GovindaRamanan/>}></Route>
          <Route path="corporate" element = {<Corporate/>}></Route>
          <Route path="dispute" element = {<Dispute/>}></Route>
          <Route path="finance" element = {<Finance/>}></Route>
          <Route path="bharat-drone-manthan-march-2024" element = {<BharatDroneManthanMarch2024/>}></Route>
          <Route path="national-green-infra-march-2024" element = {<NationalGreenInfrastructureConclaveMarch2024/>}></Route>
        </Routes>
      </BrowserRouter>
      <Footer/>
    </>
  );
}

export default App;
