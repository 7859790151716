import React from 'react'
import AboutUsHeader from './AboutUsHeader'
import Justice from './Images/justice.jpg'

const AboutUs = () => {
  return (
    <>
      <AboutUsHeader/>
      <div className='container-fluid mt-4'>
        <div className='row'>
          <div className='col-12 text-center'>
            <p className='display-6 section-title'>
              Who We Are?
            </p>
          </div>
          <div className='col-md-3 text-center'>
            <img className='img-fluid' src={Justice} alt='Justice'/>
          </div>
          <div className='col-md-9'>
            <p align='justify'>
              <strong>ARNCH LEGAL LLP</strong> is a specialist law firm having its presence throughout the country with its base in New Delhi. Arnch Legal is providing a full spectrum of solutions, having carved out a niche for itself in laws dealing with economic offences, including PMLA, PBPT, FEMA, Black Money Act, cases being investigated by SFIO. Our highly experienced team at Arnch also advices a large and diverse set of clients, including domestic and foreign commercial enterprises, financial institutions, private equity funds, venture capital funds, start-ups, government and regulatory bodies. The team at Arnch has been successful in providing timely advise and assistance to our dedicated clientele in the field of preventive compliance.
            </p>
            <p align='justify'>
              We also provide exceptional solutions in the fields of mergers & acquisitions, tax advisory, dispute resolution, arbitration, capital markets, private equity practices, including the entire gamut of civil and criminal litigation emanating thereon.
            </p>
            <p align='justify'>
              With constantly evolving legal and business environment, we at Arnch are determined to help our clients to strive ahead and embrace the change that the future beholds for them. We are constantly in the process of ensuring that the obstacles faced by our clients are addressed holistically, in order to keep our clients ahead of the curve. Our multidisciplinary teams with diverse exposure and in-depth knowledge are frequently sought after by leading national and international organisations alike for legal and business-critical advice.
            </p>
            <p align='justify'>
              With 40+ lawyers, Three pan-India locations, we are emerging as India’s prominent full-service law firm, having the capability to put our clients ahead of the curve through varied collaborations. Arnch is a distinctive firm, in as much, as we provides wide spectrum of Advisory and Litigation services to High Networth Individuals (HNI), companies and corporate houses.
            </p>
            <p align='justify'>
              The team at Arnch has successfully and proficiently applied its knowledge across legal transactions across diverse practice areas, industries and jurisdictions. We also work with companies, financial institutions and governments to execute the most significant deals and to resolve disputes amicably.
            </p>
          </div>
        </div>
      </div>
    </>
  )
}

export default AboutUs